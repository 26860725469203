import React, {useRef, useState} from "react";
import {DashedLine} from "../../MyLineGraph/MyLineGraph";
import {LineCanvas, ResponsiveLine} from "@nivo/line";
import {getMobileAdjustedHeight, useWindowSize} from "../plotsHelpers";
import Paper from "@mui/material/Paper";
import RenderIfVisible from "react-render-if-visible";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {exportComponentAsPNG} from "react-component-export-image";
import Typography from "@mui/material/Typography";
import CustomSliceTooltip from "../Tooltips/CustomSliceTooltip";
import StackedLegend from "../StackedLegend/StackedLegend";
import TuneIcon from '@mui/icons-material/Tune';
import TimeLineSettings from "./TimeLineSettings";
import DownloadFileButton from "./DownloadButton";
import {apiUrl} from "../../../store/api_helpers";
import TimeSelector from "../../TimeSelector/TimeSelector";
import {filterTime2} from "../../TimeSelector/timeSelectorHelpers";


const GraphComponent = React.forwardRef(({
                                             data,
                                             title,
                                             xaxis,
                                             GraphComp = LineCanvas,
                                             lineProps = {},
                                             invisibleLines = {}
                                         }, ref) => {

    console.log("StandardPlot is rendering, data:", data, "title:", title);
    const {height} = useWindowSize();

    let [highlightedLines, setHighlightedLines] = useState(Object.fromEntries(
        Object.keys(data).map(key => [key, false])
    ))


    let flipHighlightedLine = (line) => setHighlightedLines({
        ...highlightedLines,
        [line]: !highlightedLines[line]
    })

    let DLC = DashedLine(highlightedLines, invisibleLines)
    let SLC = StackedLegend(highlightedLines, flipHighlightedLine, invisibleLines)


    return <div ref={ref}
                style={{height: getMobileAdjustedHeight(height, 500) - 60,}}>
        <Typography variant="h6" color="inherit" component="div" align="center">
            {title}
        </Typography>
        <GraphComp
            data={data}
            margin={{top: 15, right: 150, bottom: 65, left: 50}}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
                type: 'linear',
                stacked: false,
            }}

            // axisLeft={{
            //     legend: 'linear scale',
            //     legendOffset: 12,
            // }}
            layers={['grid', 'markers', 'areas', DLC, 'slices', 'points', 'axes', 'mesh', 'crosshair', 'legends', SLC]}
            axisBottom={{
                format: '%y %b',
                // tickValues: 'every 6 months',
                // legend: 'time scale',
                legendOffset: -12,
            }}
            // curve={select('curve', curveOptions, 'monotoneX')}
            // enablePointLabel={true}
            // pointSymbol={CustomSymbol}
            pointSize={0}
            pointBorderWidth={1}
            pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
            }}
            useMesh={true}
            enableSlices={"x"}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 5,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    // onClick: (l) => lineIndex === l.id ? setLineIndex("") : setLineIndex(l.id),
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .1)',
                                itemOpacity: 1,
                            }
                        }
                    ]
                }
            ]}
            // sliceTooltip={InterpolationSliceTooltip(data, color.values)}
            sliceTooltip={CustomSliceTooltip(true)}
            {...lineProps}
        />
    </div>
})

let StandardPlotComponent = ({settings, title, originalData, data, ...comps}) => {
    const componentRef = useRef();
    const {height} = useWindowSize();
    const [showSettings, setShowSettings] = useState(false)

    const [timeSelectorParams, updateTimeSelectorParams] = useState(null);


    return (
        <React.Fragment>

            <Paper sx={
                {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: getMobileAdjustedHeight(height, 500),
                    marginBottom: '50px'
                }
            }>

                <RenderIfVisible
                    defaultHeight={getMobileAdjustedHeight(height, 500) - 60}
                >
                    <Box display="flex">
                        <Box flexGrow={1} width={0}>
                            <GraphComponent ref={componentRef} GraphComp={ResponsiveLine} title={title}
                                            data={filterTime2(data, timeSelectorParams)}
                                            {...comps}/>
                        </Box>
                        {showSettings ? <Box>
                            <TimeLineSettings settings={settings} slideInRight={showSettings}/>
                        </Box> : null}
                    </Box>
                    <StackedLegend data={comps.data}/>
                </RenderIfVisible>
                <Box display="flex">
                    <Button
                        variant={"text"}
                        style={{marginTop: 5, marginBottom: 20, marginLeft: 15}}
                        onClick={() =>
                            exportComponentAsPNG(componentRef, {fileName: `${title}.png`})}
                    >
                        Export As PNG
                    </Button>
                    <DownloadFileButton url={`${apiUrl}create_xlsx`} fileName={`${title}.xlsx`} data={originalData}/>
                    <Box sx={{marginLeft: 'auto'}}>
                        <TimeSelector
                            timeSelectorParams={timeSelectorParams}
                            updateTimeSelectorParams={updateTimeSelectorParams}
                        />
                    </Box>
                    <Button
                        style={{marginTop: 5, marginBottom: 20, marginLeft: 10}}
                        onClick={() => setShowSettings(!showSettings)}
                    >
                        <TuneIcon/>
                    </Button>
                </Box>
            </Paper>
        </React.Fragment>
    )
        ;

}


export default StandardPlotComponent;
