import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../store/dataApi.reducer";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {millionFormat, thousandsFormat} from "../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";
import {filterNestedDictWithOffset} from "../../../common/data_helpers";
import {noFormat} from "../../../components/Plots/StandardPlot/timeLinePlotHelpers";


let path = 'workday/semrush2';

const WorkdaySemrushPage = () => {
    let data = useSelector(state => state.dataApi.data[path])
    let dispatch = useDispatch()

    //
    useEffect(() => {
        if (data === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Workday - Semrush
            </Typography>
        </Container>
        <Container>
            {data === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
        </Container>
    </>
}

const Graphs = () => {
    let data = useSelector(state => state.dataApi.data[path])

    console.log(data['traffic'])
    return <div>
        {/*{JSON.stringify(data)}*/}
        <TimeLinePlotWithSettings data={data['visits']} title='Total Visitors [millions]'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={millionFormat}/>
        <TimeLinePlotWithSettings data={data['users']} title='Unique Visitors [millions]'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={millionFormat}/>
        <TimeLinePlotWithSettings data={filterNestedDictWithOffset(data['time_on_site'], 36)}
                                  title='Average Duration per Visit  [seconds]'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={(value) => `${Math.round(value)}s`}/>
        <TimeLinePlotWithSettings data={filterNestedDictWithOffset(data['traffic'], 48)}
                                  title='Paid traffic'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={thousandsFormat}/>

        <TimeLinePlotWithSettings data={filterNestedDictWithOffset(data['trafficCost'], 60)}
                                  title='Domain wise Paid Traffic Cost per month (USD)'
                                  yFormatFun={thousandsFormat}
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
        />


        <TimeLinePlotWithSettings data={filterNestedDictWithOffset(data['domainkw_cpc'], 24)}
                                  title='Domain wise Cost per Click (USD)'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={value => value} defaultMaxY={25}/>

        <TimeLinePlotWithSettings
            data={filterNestedDictWithOffset(data['domainkw_total_keywords'], 24)}
            title='Domain wise Number of Keywords'
            defaultSmoothing={3}
            smoothingUnit={"months"}
            yFormatFun={noFormat}/>

        <TimeLinePlotWithSettings data={filterNestedDictWithOffset(data['domainkw_mean_spend'], 24)}
                                  title='Domain wise Average Spend per Keyword (USD)'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={value => value}/>

    </div>
}


export default WorkdaySemrushPage