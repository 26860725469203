import React from "react";
import withDataFetchAndTitle from "../../components/DataLoadPage";
import WorkforcePageAlexStyle from "../../components/workforce/WorkforcePageAlexStyle";

let path = 'workday/workforce';

const WorkdayWorkforcePage = ({data}) => {
    return <>
        <WorkforcePageAlexStyle data={data}/>
    </>
}


export default withDataFetchAndTitle(WorkdayWorkforcePage, path, 'Workday - Workforce')

