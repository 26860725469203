import React from "react";
import withNonEmptyData from "../../../../components/HelperComponents/NonEmptyComponent";
import AreaPlot from "../../../../components/Plots/AreaPlot/AreaPlot";


const AreaPlotViewer = ({data, params}) => {
    return <div>
        <SafeTimeLinePlotWithSettings data={data} title={params.title}
                                      defaultNormalize={true}/>
    </div>
}

const SafeTimeLinePlotWithSettings = withNonEmptyData(AreaPlot)


export default AreaPlotViewer