import withDataFetchAndTitle from "../../components/DataLoadPage";
import {roundNumber} from "../../../common/data_helpers";
import React from "react";
import {DataTable} from "../../components/app_annie/AppAnnieSummaryPage";


const LLMsHGInsightsPage = ({data}) => {
    return <>
        <DataTable
            data={data['number_customers']['series']}
            numberFormat={(num) => roundNumber(num, 1)}
            title={"Number of customers"}
            dropQuarter={false}
        />
        <div style={{height: 25}}></div>
        <DataTable
            data={data['number_customers_yoy']['series']}
            numberFormat={(num) => roundNumber(num, 0)}
            title={"Number of customers YOY"}
            dropQuarter={false}

        />
        <div style={{height: 25}}></div>
        <DataTable
            data={data['number_customers_market_share']['series']}
            numberFormat={(num) => roundNumber(num, 0)}
            title={"Number of customers Market Share"}
            dropQuarter={false}

        />
        <div style={{height: 25}}></div>
        <DataTable
            data={data['number_adds']['series']}
            numberFormat={(num) => roundNumber(num, 0)}
            title={"Number of adds"}
            dropQuarter={false}

        />
        <div style={{height: 25}}></div>
        <DataTable
            data={data['number_adds_yoy']['series']}
            numberFormat={(num) => roundNumber(num, 0)}
            title={"Number of adds YOY"}
            dropQuarter={false}

        />
        <div style={{height: 25}}></div>
        <DataTable
            data={data['number_adds_market_share']['series']}
            numberFormat={(num) => roundNumber(num, 0)}
            title={"Number of adds Market Share"}
            dropQuarter={false}

        />
        <div style={{height: 25}}></div>

    </>

}


export default withDataFetchAndTitle(
    LLMsHGInsightsPage,
    'llms/hginsights_table', 'LLMs - HG Insights',
    false,
    {padding: '10px !important'}
)
