import React from "react";
import {ReactComponent as TDGIcon} from '../../components/logos/TDG.svg';
import {ReactComponent as BillDotComIcon} from '../../components/logos/bill_dot_com_logo.svg';
import {ReactComponent as AppAnnieIcon} from '../../components/logos/data-ai-header-logo.svg';
import {ReactComponent as DatadogIcon} from '../../components/logos/ddog_logo.svg';
import Icon from '@mui/material/Icon';
import {ReactComponent as GitlabIcon} from '../../components/logos/GitlabIcon.svg';
import {ReactComponent as AmazonIcon} from '../../components/logos/amzn_logo.svg';
import {ReactComponent as AmazonShopifyIcon} from '../../components/logos/shopify-logo.svg';
import DDOGOpenTelemetryIcon from "../../components/logos/open_telemetry.png";
import {ReactComponent as ZoominfoIcon} from '../../components/logos/zoominfo_logo.svg';
import {ReactComponent as ProcoreIcon} from '../../components/logos/pcor_logo.svg';
import {ReactComponent as AutodeskIcon} from '../../components/logos/autodesk-svgrepo-com.svg';
import {ReactComponent as GuidewireIcon} from '../../components/logos/guidewire_logo.svg';
import {ReactComponent as ShopifyIcon} from '../../components/logos/shopify_icon.svg';
import {ReactComponent as DexcomIcon} from '../../components/logos/dexcom-g6-svgrepo-com.svg';
import {ReactComponent as CostarIcon} from '../../components/logos/costar-realty-information-inc-logo-vector.svg';
import {ReactComponent as MondayDotComIcon} from '../../components/logos/monday-icon-svgrepo-com.svg';
import {ReactComponent as IndeedComIcon} from '../../components/logos/indeed-svgrepo-com.svg';
import {ReactComponent as GoogleTrendsIcon} from '../../components/logos/Google Trends Icon Logo.svg';
import {ReactComponent as ETFIcon} from '../../components/logos/logo.svg';
import {ReactComponent as SpyIcon} from '../../components/logos/spy-svgrepo-com.svg';
import {ReactComponent as IBKRIcon} from '../../components/logos/ibkr_logo.svg';
import {ReactComponent as SNOWIcon} from '../../components/logos/snoflake_icon.svg';
import {ReactComponent as GithubIcon} from '../../components/logos/github.svg';
import {ReactComponent as DatabaseIcon} from '../../components/logos/database.svg';
import {ReactComponent as MongoIcon} from '../../components/logos/mongo_icon.svg';
import {ReactComponent as StackoverflowIcon} from '../../components/stackoverflow/logo-stackoverflow.svg';

import {ReactComponent as CPNGIcon} from '../../components/logos/CPNG.svg';
import {ReactComponent as LLMSIcon} from '../../components/logos/ai-svgrepo-com.svg';
import {ReactComponent as TeslaIcon} from '../../components/logos/Tesla_Motors.svg';
import {ReactComponent as FolioIcon} from '../../components/logos/appfolio-v2-svgrepo-com.svg';
import {ReactComponent as PrivcoIcon} from '../../components/logos/privcoLogo.svg';
import {ReactComponent as WorkdayIcon} from '../../components/logos/workday.svg';


const DDOGOpenTelemetryLogoComponent = () => {
    return <img src={DDOGOpenTelemetryIcon} alt="Open Telemetry Logo" style={{width: '25px', height: 'auto'}}/>
}


export const iconImports = {
    'TDGIcon': TDGIcon,
    'BillDotComIcon': BillDotComIcon,
    'AppAnnieIcon': AppAnnieIcon,
    'DatadogIcon': DatadogIcon,
    'GitlabIcon': GitlabIcon,
    'AmazonIcon': AmazonIcon,
    'AmazonShopifyIcon': AmazonShopifyIcon,
    'DDOGOpenTelemetryLogoComponent': DDOGOpenTelemetryLogoComponent,
    'ZoominfoIcon': ZoominfoIcon,
    'ProcoreIcon': ProcoreIcon,
    'AutodeskIcon': AutodeskIcon,
    'GuidewireIcon': GuidewireIcon,
    'ShopifyIcon': ShopifyIcon,
    'DexcomIcon': DexcomIcon,
    'CostarIcon': CostarIcon,
    'MondayDotComIcon': MondayDotComIcon,
    'IndeedComIcon': IndeedComIcon,
    'GoogleTrendsIcon': GoogleTrendsIcon,
    'ETFIcon': ETFIcon,
    'SpyIcon': SpyIcon,
    'IBKRIcon': IBKRIcon,
    'SNOWIcon': SNOWIcon,
    'GithubIcon': GithubIcon,
    'DatabaseIcon': DatabaseIcon,
    'MongoIcon': MongoIcon,
    'StackoverflowIcon': StackoverflowIcon,
    'CPNGIcon': CPNGIcon,
    'LLMSIcon': LLMSIcon,
    'TeslaIcon': TeslaIcon,
    'FolioIcon': FolioIcon,
    'PrivcoIcon': PrivcoIcon,
    'WorkdayIcon': WorkdayIcon,
}


export let getIconComponent = (icon) => {
    switch (icon.type) {
        case 'import':
            return iconImports[icon.name]
        case 'mui_font':
            return () => {
                return <Icon>{icon.name}</Icon>
            }
        default:
            throw new TypeError(`Unsupported type ${icon.type}`);
    }
}

