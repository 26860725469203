import React from "react";


const BlankAreaViewer = ({params}) => {
    return <div style={{height: `${params.height}px`}}>

    </div>
}


export default BlankAreaViewer