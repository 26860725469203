import React from "react";
import {roundNumber} from "../../../../common/data_helpers";
import {DataTable} from "../../app_annie/AppAnnieSummaryPage";


const LongTableViewer = ({data, params}) => {
    return <div>
        <DataTable
            data={data}
            numberFormat={(num) => roundNumber(num, 0)}
            title={params.title}
            dropQuarter={false}
        />
    </div>
}


export default LongTableViewer