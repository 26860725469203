import React from "react";
import withNonEmptyData from "../../../../components/HelperComponents/NonEmptyComponent";
import StandardPlot from "../../../../components/Plots/StandardPlot/StandardPlot";


const TimeLineViewer = ({data, params}) => {
    return <div>
        <SafeTimeLinePlotWithSettings data={data} title={params.title}
                                      otherLineProps={{curve: "linear"}}/>
    </div>
}

const SafeTimeLinePlotWithSettings = withNonEmptyData(StandardPlot)


export default TimeLineViewer