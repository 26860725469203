import withDataFetchAndTitle from "../../components/DataLoadPage";
import AppAnnieSummary from "../../components/app_annie/AppAnnieSummaryPage";
import {getFilenameFromUrl} from "../../../common/data_helpers";
import React from "react";

const ProductiviyAppAnnieSummary = ({data: {summary, ref_files}}) => {
    return <>
        {ref_files.map((ref_file, index) => (
            <>
                <a href={ref_file} target={"_blank"}>{getFilenameFromUrl(ref_file)}</a>
                <div style={{height: '25px'}}></div>

            </>))}
        < AppAnnieSummary data={summary}/>
    </>

}


export default withDataFetchAndTitle(
    ProductiviyAppAnnieSummary,
    'workday/app_annie_summary_v2', 'Workday - App Annie',
    false,
    {padding: '10px !important'}
)
