import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {fetchAuthSession} from 'aws-amplify/auth';

function DownloadFileButton({url, data, fileName}) {
    const [error, setError] = useState('');

    const handleDownload = async () => {
        try {
            const {tokens} = await fetchAuthSession();
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokens.idToken.toString()}`,
                },
                body: JSON.stringify({
                    data: data,
                    file_name: fileName,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const responseData = await response.json();

            // Validate expected response format
            if (responseData.statusCode !== 200 || !responseData.url) {
                throw new Error('Unexpected response format.');
            }

            // Initiate file download
            const link = document.createElement('a');
            link.href = responseData.url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (err) {
            setError(err.message);
        }
    };

    const handleCloseError = () => {
        setError('');
    };

    return (
        <>
            <Button
                variant={"text"}
                style={{marginTop: 5, marginBottom: 20, marginLeft: 15}}
                onClick={handleDownload}>
                Export as XLSX
            </Button>

            <Snackbar
                open={Boolean(error)}
                autoHideDuration={6000}
                onClose={handleCloseError}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <Alert onClose={handleCloseError} severity="error" sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
}

export default DownloadFileButton;
